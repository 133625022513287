//BRAND
export const name = 'Business Training Outreach';
export const company = 'Business Training Outreach';
export const websiteURL = 'https://bto.app';
export const serverURL = 'https://bto-1ug8.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/business-training-outreach.appspot.com/o/Attachment_1725430859.jpeg?alt=media&token=16115579-a6cf-476b-96ca-a4221a302d67';
export const razorpayEnabled = false;
export const paypalEnabled = true;
export const stripeEnabled = false;
export const paystackEnabled = false;
export const flutterwaveEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 9;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 99;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Ruben D. Caraway";
export const profession = 'Business Training Outreach';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/business-training-outreach.appspot.com/o/Attachment_1725430859.jpeg?alt=media&token=16115579-a6cf-476b-96ca-a4221a302d67';

//PAYPAL
export const paypalPlanIdOne = "P-7EW92149GV498904CM3MAJ3I";
export const paypalPlanIdTwo = "P-16J25423VH042633MM3MAMUA";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1OTo7CSDXmLtVnVeaHIHxqCj";
export const stripePlanIdTwo = "price_1OTo7eSDXmLtVnVeBbn82U5B";

//PAYSTACK
export const paystackPlanIdOne = "PLN_ouqmm8eo6i2k9k8";
export const paystackPlanIdTwo = "PLN_1v1xqb8io9t5lis";
export const amountInZarOne = '170';
export const amountInZarTwo = '1871';

//FLUTTERWAVE
export const flutterwavePlanIdOne = "67960";
export const flutterwavePlanIdTwo = "67961";
export const flutterwavePublicKey = "FLWPUBK_TEST-6ee1faf6460ea587f510a024ac4c2b23-X";

//SOCIAL SIGNIN
export const googleClientId = "102640884367-oislihj9aclt6da5n7b23v75jdisvrhs.apps.googleusercontent.com";
export const facebookClientId = "8065327730213158";